import * as React from "react"
import { useState } from "react"

import Seo from "../components/seo"
import Svg from "../components/svg"

import copy from "../copy"

const Header = ({ children }) => (
  <h2 className="uppercase font-body font-bold text-sm my-8">{children}</h2>
)

const Subheader = ({ children }) => (
  <h3 className="uppercase text-sm my-4">{children}</h3>
)

const Paragraph = ({ children }) => (
  <p className="font-body text-sm my-4">{children}</p>
)

const IndexPage = () => {
  const [lang, setLang] = useState("en")

  const toggleLang = () => {
    setLang(lang === "en" ? "fr" : "en")
  }

  return (
    <>
      <Seo />
      <main className="grid place-items-center p-8 md:p-16 text-grey">
        <div className="max-w-[975px]">
          <Svg
            svg="wordmark"
            className="text-white w-full"
            ariaLabel="CNCPT Wordmark"
          />

          <section className="my-16 flex flex-col md:flex-row md:justify-between font-header font-bold uppercase text-sm md:text-lg">
            <h1>{copy.title[lang]}</h1>
            <h1 className="mt-2 md:text-right">{copy.updated[lang]}</h1>
          </section>

          <Header>{copy.one.header[lang]}</Header>
          <Paragraph>{copy.one.p1[lang]}</Paragraph>
          <Paragraph>{copy.one.p2[lang]}</Paragraph>

          <Header>{copy.two.header[lang]}</Header>
          <Subheader>{copy.two.subheader1[lang]}</Subheader>
          <Paragraph>{copy.two.p1[lang]}</Paragraph>
          <Paragraph>{copy.two.subheader2[lang]}</Paragraph>
          <Paragraph>{copy.two.p2[lang]}</Paragraph>

          <Header>{copy.three.header[lang]}</Header>
          <Subheader>{copy.three.subheader1[lang]}</Subheader>
          <Paragraph>{copy.three.p1[lang]}</Paragraph>
          <Paragraph>{copy.three.subheader2[lang]}</Paragraph>
          <Paragraph>{copy.three.p2[lang]}</Paragraph>

          <Header>{copy.four.header[lang]}</Header>
          <Paragraph>{copy.four.p1[lang]}</Paragraph>
          <Paragraph>{copy.four.p2[lang]}</Paragraph>

          <Header>{copy.five.header[lang]}</Header>
          <Paragraph>{copy.five.p1[lang]}</Paragraph>

          <Header>{copy.six.header[lang]}</Header>
          <Subheader>{copy.six.subheader1[lang]}</Subheader>
          <Paragraph>{copy.six.p1[lang]}</Paragraph>
          <Paragraph>{copy.six.subheader2[lang]}</Paragraph>
          <Paragraph>{copy.six.p2[lang]}</Paragraph>

          <Header>{copy.seven.header[lang]}</Header>
          <Subheader>{copy.seven.subheader1[lang]}</Subheader>
          <Paragraph>{copy.seven.p1[lang]}</Paragraph>
          <Paragraph>{copy.seven.subheader2[lang]}</Paragraph>
          <Paragraph>{copy.seven.p2[lang]}</Paragraph>

          <Header>{copy.eight.header[lang]}</Header>
          <Paragraph>{copy.eight.p1[lang]}</Paragraph>
          <Paragraph>{copy.eight.p2[lang]}</Paragraph>

          <Header>{copy.nine.header[lang]}</Header>
          <Paragraph>{copy.nine.p1[lang]}</Paragraph>

          <Header>{copy.ten.header[lang]}</Header>
          <Paragraph>{copy.ten.p1[lang]}</Paragraph>

          <Header>{copy.eleven.header[lang]}</Header>
          <Paragraph>{copy.eleven.p1[lang]}</Paragraph>

          <Header>{copy.twelve.header[lang]}</Header>
          <Paragraph>{copy.twelve.p1[lang]}</Paragraph>
        </div>

        {/* <button
          className="uppercase border-grey border-2 rounded p-2 text-xs"
          onClick={toggleLang}
        >
          Change language to: {lang === "en" ? "fr" : "en"}
        </button> */}
      </main>
    </>
  )
}

export default IndexPage
