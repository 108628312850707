const copy = {
  title: {
    en: "Privacy Policy",
    fr: "Privacy Policy",
  },
  updated: {
    en: "Last Updated: March 21, 2022",
    fr: "Last Updated: March 21, 2022",
  },
  one: {
    header: {
      en: "1. GENERAL",
      fr: "1. GENERAL",
    },
    p1: {
      en: "This Privacy Policy (the “Privacy Policy”) describes how OPEN HXOUSE LLC, its affiliates and associated film companies (collectively, “HXOUSE,” “we,” or “us”), collect, use, and share Personal Information about you when you visit our website, www.hxouse.com or any other website under our control that links to this Privacy Policy (the “Site”) or use any of the services provided on the Site or are otherwise described in this Privacy Policy.",
      fr: "This Privacy Policy (the “Privacy Policy”) describes how OPEN HXOUSE LLC, its affiliates and associated film companies (collectively, “HXOUSE,” “we,” or “us”), collect, use, and share Personal Information about you when you visit our website, www.hxouse.com or any other website under our control that links to this Privacy Policy (the “Site”) or use any of the services provided on the Site or are otherwise described in this Privacy Policy.",
    },
    p2: {
      en: "As used in this Privacy Policy, “Personal Information” means any data that identifies, relates to or describes you, or is reasonably capable of being associated with, or could reasonably be linked to you, directly or indirectly",
      fr: "As used in this Privacy Policy, “Personal Information” means any data that identifies, relates to or describes you, or is reasonably capable of being associated with, or could reasonably be linked to you, directly or indirectly",
    },
  },
  two: {
    header: {
      en: "2. (A) COLLECTED PERSONAL INFORMATION; SOURCES; USE INFORMATION THAT YOU PROVIDE TO US",
      fr: "2. (A) COLLECTED PERSONAL INFORMATION; SOURCES; USE INFORMATION THAT YOU PROVIDE TO US",
    },
    subheader1: {
      en: "CONTACT FORM AND COMMENTS ON THE SITE",
      fr: "CONTACT FORM AND COMMENTS ON THE SITE",
    },
    p1: {
      en: "If you send us questions via the contact form on the Site, the time at which you created the comment or message and your email address will be stored along with your comment. We will collect such information to answer your question and any follow-up questions. This information is necessary for us to be able to manage our Site, manage and grow our business, and enforce and defend our legal rights.",
      fr: "If you send us questions via the contact form on the Site, the time at which you created the comment or message and your email address will be stored along with your comment. We will collect such information to answer your question and any follow-up questions. This information is necessary for us to be able to manage our Site, manage and grow our business, and enforce and defend our legal rights.",
    },
    subheader2: {
      en: "EMAIL AND OTHER CORRESPONDENCE",
      fr: "EMAIL AND OTHER CORRESPONDENCE",
    },
    p2: {
      en: "We will collect the Personal Information that you provide to us, such as your name, employment information, and contact information, if you contact us, such as through phone or email. We use this Personal Information to respond to your requests, and to manage the Site and our business.",
      fr: "We will collect the Personal Information that you provide to us, such as your name, employment information, and contact information, if you contact us, such as through phone or email. We use this Personal Information to respond to your requests, and to manage the Site and our business.",
    },
  },
  three: {
    header: {
      en: "2. (B) INFORMATION THAT WE COLLECT AUTOMATICALLY",
      fr: "2. (B) INFORMATION THAT WE COLLECT AUTOMATICALLY",
    },
    subheader1: {
      en: "COOKIES",
      fr: "COOKIES",
    },
    p1: {
      en: "Cookies are small text files that are stored on your computer and saved by your browser. We and our third party providers use cookies on parts of the Site. Cookies help make the Site more user-friendly, efficient, and secure. The Site may employ both “session cookies,” which are automatically deleted after each visit to the Site, and other cookies that remain in your device's memory until you delete them, making it possible to recognize your device when you next visit the Site. You can configure your browser to automatically accept cookies under certain conditions or to always reject cookies, or to automatically delete cookies when closing your browser. Disabling cookies may limit some of the functionality of the Site.",
      fr: "Cookies are small text files that are stored on your computer and saved by your browser. We and our third party providers use cookies on parts of the Site. Cookies help make the Site more user-friendly, efficient, and secure. The Site may employ both “session cookies,” which are automatically deleted after each visit to the Site, and other cookies that remain in your device's memory until you delete them, making it possible to recognize your device when you next visit the Site. You can configure your browser to automatically accept cookies under certain conditions or to always reject cookies, or to automatically delete cookies when closing your browser. Disabling cookies may limit some of the functionality of the Site.",
    },
    subheader2: {
      en: "GOOGLE ANALYTICS",
      fr: "GOOGLE ANALYTICS",
    },
    p2: {
      en: "We use Google Analytics, a web analysis service provided by Google, in order to better understand your use of the Site and how we can improve it. Google Analytics collects information such as how often users visit a website, what pages you visit when you do so, and what other websites you used prior to coming to such website. Google Analytics collects only the IP address assigned to you on the date you visit a website, rather than your name or other directly identifying information. We do not combine the information collected through the use of Google Analytics with your Personal Information. Although Google Analytics plants a persistent cookie on your web browser to identify you as a unique user the next time you visit a website, the cookie cannot be used by anyone but Google. Google Analytics uses its cookie(s) and the information Google Analytics collects to track your online activities over time and across different websites when you use the Site, and other websites. Google's ability to use and share information collected by Google Analytics about your visits to the Site is governed by the Google Analytics Terms of Service and the Google Privacy Policy. Google utilizes the data collected to track and examine the use of the Site, to prepare reports on its activities, and share them with other Google services. To more fully understand how Google may use the data it collects on the Site, please review “How Google Uses Information From Sites or Apps That Use Our Services” (located here).",
      fr: "We use Google Analytics, a web analysis service provided by Google, in order to better understand your use of the Site and how we can improve it. Google Analytics collects information such as how often users visit a website, what pages you visit when you do so, and what other websites you used prior to coming to such website. Google Analytics collects only the IP address assigned to you on the date you visit a website, rather than your name or other directly identifying information. We do not combine the information collected through the use of Google Analytics with your Personal Information. Although Google Analytics plants a persistent cookie on your web browser to identify you as a unique user the next time you visit a website, the cookie cannot be used by anyone but Google. Google Analytics uses its cookie(s) and the information Google Analytics collects to track your online activities over time and across different websites when you use the Site, and other websites. Google's ability to use and share information collected by Google Analytics about your visits to the Site is governed by the Google Analytics Terms of Service and the Google Privacy Policy. Google utilizes the data collected to track and examine the use of the Site, to prepare reports on its activities, and share them with other Google services. To more fully understand how Google may use the data it collects on the Site, please review “How Google Uses Information From Sites or Apps That Use Our Services” (located here).",
    },
  },
  four: {
    header: {
      en: "3. LINKED SITES AND SOCIAL MEDIA",
      fr: "3. LINKED SITES AND SOCIAL MEDIA",
    },
    p1: {
      en: "For your convenience, this Site may contain hyperlinks to other websites that are not under our control. We are not responsible for, and this Privacy Policy does not apply to, the privacy practices of those sites or of any companies that we do not own or control, and we do not endorse any of these websites or pages, or their content, products or services.",
      fr: "For your convenience, this Site may contain hyperlinks to other websites that are not under our control. We are not responsible for, and this Privacy Policy does not apply to, the privacy practices of those sites or of any companies that we do not own or control, and we do not endorse any of these websites or pages, or their content, products or services.",
    },
    p2: {
      en: "Any information that you submit to any of our pages on a social media platform or by interacting with social media plugins (e.g., the Facebook “like button”), if any, on the Site is done at your own risk and without any expectation of privacy. We cannot control the actions of other users of a social media platform and we are therefore not responsible for any information provided on a social media platform or website. Please review any such social media providers’ privacy policy and terms of use before interacting with any such platform.",
      fr: "Any information that you submit to any of our pages on a social media platform or by interacting with social media plugins (e.g., the Facebook “like button”), if any, on the Site is done at your own risk and without any expectation of privacy. We cannot control the actions of other users of a social media platform and we are therefore not responsible for any information provided on a social media platform or website. Please review any such social media providers’ privacy policy and terms of use before interacting with any such platform.",
    },
  },
  five: {
    header: {
      en: "4. OTHER USES OF PERSONAL INFORMATION",
      fr: "4. OTHER USES OF PERSONAL INFORMATION",
    },
    p1: {
      en: "In addition to the uses described above, we may use all of the categories of Personal Information that we and third party services providers collect through the Site to operate and deliver the Site, to provide the services offered on the Site, improve the user experience on the Site, address Site integrity or security issues, and conduct research and analysis to maintain, protect, develop, and improve the Site. We may also use Personal Information as necessary to ensure compliance with any of our policies, applicable legal or regulatory requirements, to enforce and defend our legal rights or any other lawful purpose.",
      fr: "In addition to the uses described above, we may use all of the categories of Personal Information that we and third party services providers collect through the Site to operate and deliver the Site, to provide the services offered on the Site, improve the user experience on the Site, address Site integrity or security issues, and conduct research and analysis to maintain, protect, develop, and improve the Site. We may also use Personal Information as necessary to ensure compliance with any of our policies, applicable legal or regulatory requirements, to enforce and defend our legal rights or any other lawful purpose.",
    },
  },
  six: {
    header: {
      en: "5. PRIVACY RIGHTS",
      fr: "5. PRIVACY RIGHTS",
    },
    subheader1: {
      en: "DO NOT TRACK REQUESTS",
      fr: "DO NOT TRACK REQUESTS",
    },
    p1: {
      en: "Certain state laws require that we indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. We adhere to the standards set out in this Privacy Policy and do not monitor or follow any Do Not Track browser requests.",
      fr: "Certain state laws require that we indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. We adhere to the standards set out in this Privacy Policy and do not monitor or follow any Do Not Track browser requests.",
    },
    subheader2: {
      en: "OTHER DATA PROTECTION RIGHTS",
      fr: "OTHER DATA PROTECTION RIGHTS",
    },
    p2: {
      en: "Subject to applicable data protection laws, you may have the right to access, update, and correct inaccuracies in your Personal Information. Please send such requests info@hxouse.com. We may request certain Personal Information for the purposes of verifying the identity of the individual seeking access to their Personal Information.",
      fr: "Subject to applicable data protection laws, you may have the right to access, update, and correct inaccuracies in your Personal Information. Please send such requests info@hxouse.com. We may request certain Personal Information for the purposes of verifying the identity of the individual seeking access to their Personal Information.",
    },
  },
  seven: {
    header: {
      en: "6. SECURITY AND RETENTION",
      fr: "6. SECURITY AND RETENTION",
    },
    subheader1: {
      en: "DATA SECURITY",
      fr: "DATA SECURITY",
    },
    p1: {
      en: "We take commercially reasonable efforts to protect against unauthorized access to or use of your Personal Information. Nevertheless, no security system is impenetrable. We cannot guarantee that information that users of the Site transmit or otherwise supply will be totally secure.",
      fr: "We take commercially reasonable efforts to protect against unauthorized access to or use of your Personal Information. Nevertheless, no security system is impenetrable. We cannot guarantee that information that users of the Site transmit or otherwise supply will be totally secure.",
    },
    subheader2: {
      en: "PERSONAL INFORMATION RETENTION",
      fr: "PERSONAL INFORMATION RETENTION",
    },
    p2: {
      en: "We retain the Personal Information that we collect where we have an legitimate business need to do so, such as, for example, to provide the Site or related services, maintain our records, or to comply with an applicable legal requirement.",
      fr: "We retain the Personal Information that we collect where we have an legitimate business need to do so, such as, for example, to provide the Site or related services, maintain our records, or to comply with an applicable legal requirement.",
    },
  },
  eight: {
    header: {
      en: "7. WITH WHOM DO WE SHARE INFORMATION THAT WE COLLECT?",
      fr: "7. WITH WHOM DO WE SHARE INFORMATION THAT WE COLLECT?",
    },
    p1: {
      en: "In addition to the disclosures mentioned or described elsewhere in this Privacy Policy, we may also disclose Personal Information if we become subject to a subpoena or court order, if we are otherwise legally required to disclose information, to establish or exercise our legal rights, to enforce our Terms of Use, to assert and defend against legal claims, if we believe such disclosure is necessary to investigate, prevent, or take other action regarding actual or suspected illegal or fraudulent activities or potential threats to the physical safety or well-being of any person, or for any other lawful purpose.",
      fr: "In addition to the disclosures mentioned or described elsewhere in this Privacy Policy, we may also disclose Personal Information if we become subject to a subpoena or court order, if we are otherwise legally required to disclose information, to establish or exercise our legal rights, to enforce our Terms of Use, to assert and defend against legal claims, if we believe such disclosure is necessary to investigate, prevent, or take other action regarding actual or suspected illegal or fraudulent activities or potential threats to the physical safety or well-being of any person, or for any other lawful purpose.",
    },
    p2: {
      en: "As HXOUSE grows and develops our business, it is possible that its corporate structure or organization might change or that it might merge or otherwise combine with, or that it or portions of its business might be acquired by, another company. In any such transactions, customer information generally is, and should be expected to be, one of the transferred business assets. Also, if any bankruptcy, liquidation or reorganization proceeding is brought by or against us, Personal Information may be considered a company asset that may be sold or transferred to third parties.",
      fr: "As HXOUSE grows and develops our business, it is possible that its corporate structure or organization might change or that it might merge or otherwise combine with, or that it or portions of its business might be acquired by, another company. In any such transactions, customer information generally is, and should be expected to be, one of the transferred business assets. Also, if any bankruptcy, liquidation or reorganization proceeding is brought by or against us, Personal Information may be considered a company asset that may be sold or transferred to third parties.",
    },
  },
  nine: {
    header: {
      en: "8. CHILDREN",
      fr: "8. CHILDREN",
    },
    p1: {
      en: "The Site is intended for a general audience and is not intended for use or viewing by children under 13 years of age. We do not knowingly collect information from or about children or sell products to children under the age of 13. If you are under the age of 13, you must ask your parent or guardian to assist you in using the System. Furthermore, we may restrict entries to any contests, sweepstakes or promotions to entrants who are at least 18 years of age.",
      fr: "The Site is intended for a general audience and is not intended for use or viewing by children under 13 years of age. We do not knowingly collect information from or about children or sell products to children under the age of 13. If you are under the age of 13, you must ask your parent or guardian to assist you in using the System. Furthermore, we may restrict entries to any contests, sweepstakes or promotions to entrants who are at least 18 years of age.",
    },
  },
  ten: {
    header: {
      en: "9. VISITING THE SYSTEM FROM OUTSIDE CANADA",
      fr: "9. VISITING THE SYSTEM FROM OUTSIDE CANADA",
    },
    p1: {
      en: "If you are visiting the System, or any part thereof, from outside of Canada, please be aware that your information may be transferred to, stored or processed in Canada. The data protection and other laws of the Canada and other countries might not be as comprehensive as those in your country, but please be assured that we take steps to protect your privacy.",
      fr: "If you are visiting the System, or any part thereof, from outside of Canada, please be aware that your information may be transferred to, stored or processed in Canada. The data protection and other laws of the Canada and other countries might not be as comprehensive as those in your country, but please be assured that we take steps to protect your privacy.",
    },
  },
  eleven: {
    header: {
      en: "10. CHANGES TO OUR PRIVACY POLICY",
      fr: "10. CHANGES TO OUR PRIVACY POLICY",
    },
    p1: {
      en: "We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the effective date located at the top of this Privacy Policy. All such updates will be effective immediately upon notice thereof, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Site. This Privacy Policy is our notice to you, and does not constitute a contract between you and us. The version of this Privacy Policy posted on the Site on each respective date you visit the Site shall be the Privacy Policy applicable to your access and use of the Site on that date. Our electronically or otherwise properly stored copies of this Privacy Policy shall be deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this Privacy Policy which were in force on each respective date you visited the Site.",
      fr: "We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the effective date located at the top of this Privacy Policy. All such updates will be effective immediately upon notice thereof, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Site. This Privacy Policy is our notice to you, and does not constitute a contract between you and us. The version of this Privacy Policy posted on the Site on each respective date you visit the Site shall be the Privacy Policy applicable to your access and use of the Site on that date. Our electronically or otherwise properly stored copies of this Privacy Policy shall be deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this Privacy Policy which were in force on each respective date you visited the Site.",
    },
  },
  twelve: {
    header: {
      en: "11. CONTACT US",
      fr: "11. CONTACT US",
    },
    p1: {
      en: "If you have any questions or concerns about this privacy policy, or to exercise any of the privacy rights described above, please contact us at info@hxouse.com.",
      fr: "If you have any questions or concerns about this privacy policy, or to exercise any of the privacy rights described above, please contact us at info@hxouse.com.",
    },
  },
}

export default copy
